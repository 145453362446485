import type { TokenType } from 'types/api/token';
import type { TokensSortingValue } from 'types/api/tokens';

import config from 'configs/app';
import getFilterValuesFromQuery from 'lib/getFilterValuesFromQuery';
import { TOKEN_TYPE_IDS } from 'lib/token/tokenTypes';
import type { TOption } from 'ui/shared/sort/Option';

export const SORT_OPTIONS: Array<TOption<TokensSortingValue>> = [
  { title: 'Default', id: undefined },
  { title: 'Price ascending', id: 'fiat_value-asc' },
  { title: 'Price descending', id: 'fiat_value-desc' },
  { title: 'Holders ascending', id: 'holder_count-asc' },
  { title: 'Holders descending', id: 'holder_count-desc' },
  { title: 'On-chain market cap ascending', id: 'circulating_market_cap-asc' },
  { title: 'On-chain market cap descending', id: 'circulating_market_cap-desc' },
];

export const getTokenFilterValue = (getFilterValuesFromQuery<TokenType>).bind(null, TOKEN_TYPE_IDS);

const bridgedTokensChainIds = (() => {
  const feature = config.features.bridgedTokens;
  if (!feature.isEnabled) {
    return [];
  }

  return feature.chains.map(chain => chain.id);
})();
export const getBridgedChainsFilterValue = (getFilterValuesFromQuery<string>).bind(null, bridgedTokensChainIds);

export const DEPRECATED_TOKENS = [
  "0x60a8b05f812c6ac43d0a5bcc39e719d5e31cccd6", // deprecated testnet WSX
  "0x49dffd15548bd3d7254648d587e47b9b76356231", // deprecated testnet WSXFLAT
  "0xbe487be0450757174a5003da62b83c710cd3a4ba", // deprecated testnet WSXFLAT2
  "0x90d27e008d5db7f0a3c90a15a8dcc4ca18cfc670", // deprecated mainnet WSX
  "0x10837bb8d7c3e017893e8f73c765914f6d16421b", // deprecated mainnet WSX
  "0x433d515982cad8e41b8c0201f97cb87e4e1a732c", // deprecated mainnet WSX
  "0xa0cb58e7f783fce0f4042c790ea3045c48cd51e8", // deprecated mainnet SHARK
  "0x475ec4f6c9c61c02aa11d38c959f266bb6f7a9fd", // deprecated mainnet USDC
  "0xb297237efd321cebb62c5c891d4b191a881beb2e", // deprecated mainnet WETH
  "0x0fd9b5ac5a61e5285730ada2f5d1b733f88d3434", // deprecated mainnet LP
  "0x2eb64684e240f5035e4401dc07d023b4dec39234", // deprecated mainnet LP
  "0xbe1c99da6119d83ac42ed9c084c028729ad6b7d7", // deprecated mainnet LP
  "0xfbfce65dfba42ff80ec26792905170f8f3a89303", // deprecated mainnet LP
  "0x3af0ed081f4434a2dc76abd14ce6daa5c000634e", // deprecated mainnet LP
  "0xe871d9437a58c6e8a8fa01f24c4e87962aaee6b7", // deprecated mainnet LP
  "0x92927994c295ad12e3f879c6ef55095cd1753da7", // deprecated mainnet LP
  "0x364ef16261efe2266a04afccd35a3a5f1116b14d", // deprecated mainnet LP
];
